import React from 'react';

import image from '../images/pages/emailVerification.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import { Header, Headline, Wrapper, below, ReferralImage } from '../styles';

const EmailVerification = ({ location }) => (
	<Layout>
		<MetaData
			title="Envel - Email Verification"
			description="Instructions to verify your email"
			image="images/social/social.png"
			location={location}
		/>

		<Wrapper>
			<ReferralImage src={image} />
		</Wrapper>

		<Header center>Email Verification</Header>
		<Headline center>Unfortunately we cannot verify your Email through your laptop or PC.</Headline>
		<Headline center>Please tap on the Verification button on your phone in order to proceed.</Headline>
	</Layout>
);

export default EmailVerification;
